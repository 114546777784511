import { Bounce, toast } from "react-toastify";

export const success = (text) => {
    return toast(text, {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "top-right",
        type: "success",
    });
};

export const error = (text) => {
    return toast(text, {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "top-right",
        type: "error",
    });
};

export const warning = (text) => {
    return toast(text, {
        transition: Bounce,
        closeButton: true,
        autoClose: 3000,
        position: "top-right",
        type: "warning",
    });
};
