export const mongoObjectId = function () {
    const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

    const objectId =
        timestamp +
        "xxxxxxxxxxxxxxxx"
            .replace(/[x]/g, function () {
                return ((Math.random() * 16) | 0).toString(16);
            })
            .toLowerCase();

    return objectId;
};

export const computeElapsed = function (currentDate, notificationDate) {
    const elapsed = currentDate - notificationDate;
    // Calculate the number of days left
    const days = Math.floor(elapsed / 86400);
    // After deducting the days calculate the number of hours left
    const hours = Math.floor((elapsed - days * 86400) / 3600);
    // After days and hours , how many minutes are left
    const minutes = Math.floor((elapsed - days * 86400 - hours * 3600) / 60);

    let str = "Il y a ";

    if (days > 0) {
        str = str + `${days} jour${days === 1 ? "" : "s"}`;
    } else if (hours > 0) {
        str = str + `${hours} heure${hours === 1 ? "" : "s"}`;
    } else if (minutes > 0) {
        str = str + `${minutes} minute${minutes === 1 ? "" : "s"}`;
    } else {
        str = str + "quelques secondes";
    }

    return str;
};

export const flattenSteps = function (steps, level = 0) {
    const flattenedSteps = [];

    for (let i = 0; i < steps.length; i++) {
        const step = steps[i];

        const children = step.children;
        delete step.children;

        if (level === 0) {
            delete step.condition;
        }

        if (children !== undefined && children.length > 0) {
            flattenedSteps.push({ ...step, level: level, hasChild: true });

            children.forEach((e) => {
                let values = [];
                if (e.condition !== undefined) {
                    if (step._id !== e.condition.step_id) {
                        values = [];
                    } else {
                        values = e.condition.values;
                    }
                }

                e["condition"] = {
                    step_id: step._id,
                    values: values,
                };
            });
            flattenedSteps.push(...flattenSteps(children, level + 1));
        } else {
            flattenedSteps.push({ ...step, level: level, hasChild: false });
        }
    }

    return flattenedSteps;
};

export const unflattenSteps = function (steps) {
    steps = JSON.parse(JSON.stringify(steps));

    const maxLevel = Math.max(...steps.map((step) => step.level)) + 1;

    for (let level = maxLevel; level > 0; level--) {
        for (let index = 0; index < steps.length; index++) {
            if (
                steps[index].level === undefined ||
                steps[index].level === level
            ) {
                const parentId = steps[index].condition.step_id;
                const parentIndex = steps.map((e) => e._id).indexOf(parentId);

                if (steps[parentIndex].children === undefined) {
                    steps[parentIndex].children = [];
                }

                steps[parentIndex].children.push(steps[index]);
                steps.splice(index, 1);
                index--;
            }
        }
    }
    return steps;
};

export const validateSteps = function (steps) {
    let issues = [];

    const stepNameArr = steps.map((e) => e.name);

    if (new Set(stepNameArr).size !== stepNameArr.length) {
        issues.push(`Au moins deux étapes portent le même nom`);
    }

    steps.forEach((step) => {
        if (step.sentence === "") {
            issues.push(`Le texte de l'étape ${step.name} n'est pas renseigné`);
        }

        if (step.condition && step.condition.step_id) {
            const conditionalStepIndex = steps
                .map((e) => e._id.toString())
                .indexOf(step.condition.step_id.toString());

            if (conditionalStepIndex < 0) {
                issues.push(
                    `L'étape dont dépend l'étape ${step.name} n'est pas présente dans l'arborescence`
                );
            } else {
                const conditionalStep = steps[conditionalStepIndex];

                if (conditionalStep.type !== "qcm") {
                    issues.push(
                        `L'étape dont dépend l'étape ${step.name} n'est pas de type Boutons`
                    );
                }

                if (step.condition.values.length === 0) {
                    issues.push(
                        `L'étape ${step.name} dépend d'une autre étape mais ne possède pas de condition d'activation`
                    );
                }

                if (
                    step.condition.values.length ===
                    conditionalStep.options.length
                ) {
                    issues.push(
                        `L'étape ${step.name} dépend d'une autre étape mais possède toutes les conditions d'activation`
                    );
                }
            }
        }

        if (step.type === "qcm") {
            if (step.options.length < 2) {
                issues.push(
                    `L'étape ${step.name} est de type Boutons et doit possèder au moins 2 réponses possibles`
                );
            }
        }

        if (step.type === "numberfield" || step.type === "salaryfield") {
            if (step.numberScores.length < 2) {
                issues.push(
                    `L'étape ${step.name} est de type Nombre/Rémunération et doit possèder au moins 2 intervalles possibles`
                );
            } else {
                let holeInNumbers = false;

                const minValueArr = step.numberScores.map((e) =>
                    parseInt(e.minValue)
                );
                const maxValueArr = step.numberScores.map((e) =>
                    parseInt(e.maxValue)
                );

                const minValue = Math.min(...minValueArr);
                const maxValue = Math.max(...maxValueArr);

                step.numberScores.forEach((score) => {
                    if (
                        parseInt(score.minValue) !== minValue &&
                        !maxValueArr.includes(parseInt(score.minValue))
                    ) {
                        holeInNumbers = true;
                    }
                    if (
                        parseInt(score.maxValue) !== maxValue &&
                        !minValueArr.includes(parseInt(score.maxValue))
                    ) {
                        holeInNumbers = true;
                    }
                });

                if (holeInNumbers) {
                    issues.push(
                        `L'étape ${step.name} est de type Nombre/Rémunération et il y a des trous dans les intervalles renseignés`
                    );
                }
            }
        }

        if (step.type === "datefield") {
            if (step.dateScores === undefined || step.dateScores.length < 2) {
                issues.push(
                    `L'étape ${step.name} est de type Date et doit possèder au moins 2 intervalles possibles`
                );
            } else {
                let holeInDates = false;

                const startValueArr = step.dateScores.map((e) =>
                    new Date(e.startValue).getTime()
                );
                const endValueArr = step.dateScores.map((e) =>
                    new Date(e.endValue).getTime()
                );

                const startValue = Math.min(...startValueArr);
                const endValue = Math.max(...endValueArr);

                step.dateScores.forEach((score) => {
                    if (
                        score.startValue === undefined ||
                        score.endValue === undefined
                    ) {
                        holeInDates = true;
                        return;
                    }

                    const startTime = new Date(score.startValue).getTime();
                    const endTime = new Date(score.endValue).getTime();

                    if (
                        startTime !== startValue &&
                        !endValueArr.includes(startTime)
                    ) {
                        holeInDates = true;
                    }
                    if (
                        endTime !== endValue &&
                        !startValueArr.includes(endTime)
                    ) {
                        holeInDates = true;
                    }
                });

                if (holeInDates) {
                    issues.push(
                        `L'étape ${step.name} est de type Date et il y a des trous dans les intervalles renseignés`
                    );
                }
            }
        }
    });

    return issues;
};

export const removeMandatoryMentions = function (steps, previousMandatoryTree) {
    if (previousMandatoryTree !== null && previousMandatoryTree.steps) {
        previousMandatoryTree.steps.forEach((mandatoryStep) => {
            const id = mandatoryStep._id;
            const regex = new RegExp(
                `@\\[[A-zÀ-ú0-9 !?-_,;]+\\]\\(${id}\\)`,
                "g"
            );
            steps.forEach((step) => {
                step.sentence = step.sentence.replace(regex, "");
            });
        });
    }

    return steps;
};

export const standardDeviation = function (arr) {
    let stddev = 1;

    const n = arr.length;
    let sum = 0;

    arr.forEach(function (data) {
        sum += data;
    });

    const mean = sum / n;

    let variance = 0.0;
    let v1 = 0.0;
    let v2 = 0.0;

    if (n !== 1) {
        for (var i = 0; i < n; i++) {
            v1 = v1 + (arr[i] - mean) * (arr[i] - mean);
            v2 = v2 + (arr[i] - mean);
        }

        v2 = (v2 * v2) / n;
        variance = (v1 - v2) / (n - 1);
        if (variance < 0) {
            variance = 0;
        }
        stddev = Math.sqrt(variance);
    }

    return Math.round(stddev * 10) / 10;
};

export const getJobUrl = function (ats, jobId) {
    if (
        ats === undefined ||
        ats === null ||
        jobId === undefined ||
        jobId === null
    ) {
        return null;
    }

    if (ats.name === "Zenploy") {
        return `https://www.zenploy.io/app/job/${jobId}`;
    } else {
        return null;
    }
};

export const getConversationUrl = function (ats, conversationId, candidateId) {
    if (
        ats === undefined ||
        ats === null ||
        conversationId === undefined ||
        conversationId === null
    ) {
        return null;
    }

    if (ats.name === "Zenploy") {
        if (candidateId === undefined || candidateId === null) {
            return null;
        }

        return `https://www.zenploy.io/app/candidate/${candidateId}?application_id=${conversationId}`;
    } else {
        return null;
    }
};

export const getCandidateUrl = function (ats, candidateId) {
    if (
        ats === undefined ||
        ats === null ||
        candidateId === undefined ||
        candidateId === null
    ) {
        return null;
    }

    if (ats.name === "Zenploy") {
        // return `https://www.zenploy.io/app/candidate/${candidateId}`;
        return null;
    } else {
        return null;
    }
};
