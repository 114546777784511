import cx from "classnames";
import React, { Component, Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import HeaderLogo from "../AppLogo";
import Nav from "../AppNav/VerticalNavWrapper";




class AppSidebar extends Component {
    state = {};

    toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };

    render() {
        let backgroundColor = "",
            enableSidebarShadow = true,
            backgroundImageOpacity = "opacity-06";

        return (
            <Fragment>
                <div
                    className="sidebar-mobile-overlay"
                    onClick={this.toggleMobileSidebar}
                />
                <CSSTransitionGroup
                    component="div"
                    className={cx("app-sidebar", backgroundColor, {
                        "sidebar-shadow": enableSidebarShadow,
                    })}
                    transitionName="SidebarAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                    <HeaderLogo />
                    <PerfectScrollbar>
                        <div className="app-sidebar__inner">
                            <Nav />
                        </div>
                    </PerfectScrollbar>
                    <div
                        className={cx("app-sidebar-bg", backgroundImageOpacity)}
                        style={{
                            backgroundImage: null,
                        }}
                    ></div>
                </CSSTransitionGroup>
            </Fragment>
        );
    }
}

export default AppSidebar;
