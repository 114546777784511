export const RESET_NEW_OFFER = "RESET_NEW_OFFER";
export const SET_NEW_OFFER = "SET_NEW_OFFER";

export const SET_NEW_OFFER_ID = "SET_NEW_OFFER_ID";
export const SET_NEW_OFFER_NAME = "SET_NEW_OFFER_NAME";
export const SET_NEW_OFFER_DISPLAY_NAME = "SET_NEW_OFFER_DISPLAY_NAME";
export const SET_NEW_OFFER_TEAM = "SET_NEW_OFFER_TEAM";
export const SET_NEW_OFFER_COMPANY = "SET_NEW_OFFER_COMPANY";
export const SET_NEW_OFFER_LOCATION = "SET_NEW_OFFER_LOCATION";
export const SET_NEW_OFFER_CONTRACT_TYPE = "SET_NEW_OFFER_CONTRACT_TYPE";
export const SET_NEW_OFFER_ATS = "SET_NEW_OFFER_ATS";
export const SET_NEW_OFFER_EXTERNAL_REF = "SET_NEW_OFFER_EXTERNAL_REF";

export const SET_NEW_OFFER_MANDATORY_TREE = "SET_NEW_OFFER_MANDATORY_TREE";

export const SET_NEW_OFFER_REQUIREMENTS = "SET_NEW_OFFER_REQUIREMENTS";
export const ADD_NEW_OFFER_REQUIREMENT = "ADD_NEW_OFFER_REQUIREMENT";
export const REMOVE_NEW_OFFER_REQUIREMENT = "REMOVE_NEW_OFFER_REQUIREMENT";

export const SET_NEW_OFFER_SKILLS = "SET_NEW_OFFER_SKILLS";
export const ADD_NEW_OFFER_SKILL = "ADD_NEW_OFFER_SKILL";
export const REMOVE_NEW_OFFER_SKILL = "REMOVE_NEW_OFFER_SKILL";

export const SET_NEW_OFFER_STEP_NAME = "SET_NEW_OFFER_STEP_NAME";
export const SET_NEW_OFFER_STEP_SKILL = "SET_NEW_OFFER_STEP_SKILL";
export const SET_NEW_OFFER_STEP_REQUIREMENT = "SET_NEW_OFFER_STEP_REQUIREMENT";
export const SET_NEW_OFFER_STEP_CONDITION = "SET_NEW_OFFER_STEP_CONDITION";
export const SET_NEW_OFFER_STEP_TYPE = "SET_NEW_OFFER_STEP_TYPE";
export const SET_NEW_OFFER_STEP_SENTENCE = "SET_NEW_OFFER_STEP_SENTENCE";
export const SET_NEW_OFFER_STEP_END_CHAT = "SET_NEW_OFFER_STEP_END_CHAT";
export const SET_NEW_OFFER_STEP_VARIABLE = "SET_NEW_OFFER_STEP_VARIABLE";
export const SET_NEW_OFFER_STEP_WEIGHT = "SET_NEW_OFFER_STEP_WEIGHT";

export const ADD_NEW_OFFER_STEP_OPTION = "ADD_NEW_OFFER_STEP_OPTION";
export const REMOVE_NEW_OFFER_STEP_OPTION = "REMOVE_NEW_OFFER_STEP_OPTION";
export const SET_NEW_OFFER_STEP_OPTION_LABEL =
    "SET_NEW_OFFER_STEP_OPTION_LABEL";
export const SET_NEW_OFFER_STEP_OPTION_VALUE =
    "SET_NEW_OFFER_STEP_OPTION_VALUE";
export const SET_NEW_OFFER_STEP_OPTION_SCORE =
    "SET_NEW_OFFER_STEP_OPTION_SCORE";
export const SET_NEW_OFFER_STEP_OPTION_DEALBREAKER =
    "SET_NEW_OFFER_STEP_OPTION_DEALBREAKER";

export const ADD_NEW_OFFER_STEP_OPTION_NUMBER =
    "ADD_NEW_OFFER_STEP_OPTION_NUMBER";
export const REMOVE_NEW_OFFER_STEP_OPTION_NUMBER =
    "REMOVE_NEW_OFFER_STEP_OPTION_NUMBER";
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN =
    "SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN";
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX =
    "SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX";
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE =
    "SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE";
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER =
    "SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER";

export const ADD_NEW_OFFER_STEP_OPTION_DATE = "ADD_NEW_OFFER_STEP_OPTION_DATE";
export const REMOVE_NEW_OFFER_STEP_OPTION_DATE =
    "REMOVE_NEW_OFFER_STEP_OPTION_DATE";
export const SET_NEW_OFFER_STEP_OPTION_DATE_START =
    "SET_NEW_OFFER_STEP_OPTION_DATE_START";
export const SET_NEW_OFFER_STEP_OPTION_DATE_END =
    "SET_NEW_OFFER_STEP_OPTION_DATE_END";
export const SET_NEW_OFFER_STEP_OPTION_DATE_SCORE =
    "SET_NEW_OFFER_STEP_OPTION_DATE_SCORE";
export const SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER =
    "SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER";

export const SET_NEW_OFFER_STEPS = "SET_NEW_OFFER_STEPS";
export const SET_NEW_OFFER_SPECIFIC_STEP = "SET_NEW_OFFER_SPECIFIC_STEP";
export const ADD_NEW_OFFER_STEP = "ADD_NEW_OFFER_STEP";
export const REMOVE_NEW_OFFER_STEP = "REMOVE_NEW_OFFER_STEP";

export const resetNewOffer = () => ({
    type: RESET_NEW_OFFER,
});

export const setNewOffer = (offer) => ({
    type: SET_NEW_OFFER,
    offer,
});

export const setNewOfferId = (newOfferId) => ({
    type: SET_NEW_OFFER_ID,
    newOfferId,
});

export const setNewOfferName = (newOfferName) => ({
    type: SET_NEW_OFFER_NAME,
    newOfferName,
});

export const setNewOfferDisplayName = (newOfferDisplayName) => ({
    type: SET_NEW_OFFER_DISPLAY_NAME,
    newOfferDisplayName,
});

export const setNewOfferTeam = (newOfferTeam) => ({
    type: SET_NEW_OFFER_TEAM,
    newOfferTeam,
});

export const setNewOfferCompany = (newOfferCompany) => ({
    type: SET_NEW_OFFER_COMPANY,
    newOfferCompany,
});

export const setNewOfferLocation = (newOfferLocation) => ({
    type: SET_NEW_OFFER_LOCATION,
    newOfferLocation,
});

export const setNewOfferContractType = (newOfferContractType) => ({
    type: SET_NEW_OFFER_CONTRACT_TYPE,
    newOfferContractType,
});

export const setNewOfferAts = (newOfferAts) => ({
    type: SET_NEW_OFFER_ATS,
    newOfferAts,
});

export const setNewOfferExternalRef = (newOfferExternalRef) => ({
    type: SET_NEW_OFFER_EXTERNAL_REF,
    newOfferExternalRef,
});

export const setNewOfferMandatoryTree = (newOfferMandatoryTree) => ({
    type: SET_NEW_OFFER_MANDATORY_TREE,
    newOfferMandatoryTree,
});

export const setNewOfferSteps = (newOfferSteps) => ({
    type: SET_NEW_OFFER_STEPS,
    newOfferSteps,
});

export const setNewOfferRequirements = (newOfferRequirements) => ({
    type: SET_NEW_OFFER_REQUIREMENTS,
    newOfferRequirements,
});

export const addNewOfferRequirement = (newOfferRequirement) => ({
    type: ADD_NEW_OFFER_REQUIREMENT,
    newOfferRequirement,
});

export const removeNewOfferRequirement = (requirementId) => ({
    type: REMOVE_NEW_OFFER_REQUIREMENT,
    requirementId,
});

export const setNewOfferSkills = (newOfferSkills) => ({
    type: SET_NEW_OFFER_SKILLS,
    newOfferSkills,
});

export const addNewOfferSkill = (newOfferSkill) => ({
    type: ADD_NEW_OFFER_SKILL,
    newOfferSkill,
});

export const removeNewOfferSkill = (skillId) => ({
    type: REMOVE_NEW_OFFER_SKILL,
    skillId,
});

export const setNewOfferStepName = (newOfferStepName) => ({
    type: SET_NEW_OFFER_STEP_NAME,
    newOfferStepName,
});

export const setNewOfferStepSkill = (data) => ({
    type: SET_NEW_OFFER_STEP_SKILL,
    data,
});

export const setNewOfferStepRequirement = (data) => ({
    type: SET_NEW_OFFER_STEP_REQUIREMENT,
    data,
});

export const setNewOfferStepCondition = (newOfferStepCondition) => ({
    type: SET_NEW_OFFER_STEP_CONDITION,
    newOfferStepCondition,
});

export const setNewOfferStepType = (newOfferStepType) => ({
    type: SET_NEW_OFFER_STEP_TYPE,
    newOfferStepType,
});

export const setNewOfferStepSentence = (newOfferStepSentence) => ({
    type: SET_NEW_OFFER_STEP_SENTENCE,
    newOfferStepSentence,
});

export const setNewOfferStepEndChat = (newOfferStepEndChat) => ({
    type: SET_NEW_OFFER_STEP_END_CHAT,
    newOfferStepEndChat,
});

export const setNewOfferStepVariable = (newOfferStepVariable) => ({
    type: SET_NEW_OFFER_STEP_VARIABLE,
    newOfferStepVariable,
});

export const setNewOfferStepWeight = (newOfferStepWeight) => ({
    type: SET_NEW_OFFER_STEP_WEIGHT,
    newOfferStepWeight,
});

export const addNewOfferStepOption = (stepId) => ({
    type: ADD_NEW_OFFER_STEP_OPTION,
    stepId,
});

export const removeNewOfferStepOption = (stepOptionIndex) => ({
    type: REMOVE_NEW_OFFER_STEP_OPTION,
    stepOptionIndex,
});

export const setNewOfferStepOptionLabel = (newOfferStepOptionLabel) => ({
    type: SET_NEW_OFFER_STEP_OPTION_LABEL,
    newOfferStepOptionLabel,
});

export const setNewOfferStepOptionValue = (newOfferStepOptionValue) => ({
    type: SET_NEW_OFFER_STEP_OPTION_VALUE,
    newOfferStepOptionValue,
});

export const setNewOfferStepOptionScore = (newOfferStepOptionScore) => ({
    type: SET_NEW_OFFER_STEP_OPTION_SCORE,
    newOfferStepOptionScore,
});

export const setNewOfferStepOptionDealbreaker = (
    newOfferStepOptionDealbreaker
) => ({
    type: SET_NEW_OFFER_STEP_OPTION_DEALBREAKER,
    newOfferStepOptionDealbreaker,
});

export const addNewOfferStepOptionNumber = (stepId) => ({
    type: ADD_NEW_OFFER_STEP_OPTION_NUMBER,
    stepId,
});

export const removeNewOfferStepOptionNumber = (stepOptionNumberIndex) => ({
    type: REMOVE_NEW_OFFER_STEP_OPTION_NUMBER,
    stepOptionNumberIndex,
});

export const setNewOfferStepOptionNumberMin = (
    newOfferStepOptionNumberMin
) => ({
    type: SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN,
    newOfferStepOptionNumberMin,
});

export const setNewOfferStepOptionNumberMax = (
    newOfferStepOptionNumberMax
) => ({
    type: SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX,
    newOfferStepOptionNumberMax,
});

export const setNewOfferStepOptionNumberScore = (
    newOfferStepOptionNumberScore
) => ({
    type: SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE,
    newOfferStepOptionNumberScore,
});

export const setNewOfferStepOptionNumberDealbreaker = (
    newOfferStepOptionNumberDealbreaker
) => ({
    type: SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER,
    newOfferStepOptionNumberDealbreaker,
});

export const addNewOfferStepOptionDate = (stepId) => ({
    type: ADD_NEW_OFFER_STEP_OPTION_DATE,
    stepId,
});

export const removeNewOfferStepOptionDate = (data) => ({
    type: REMOVE_NEW_OFFER_STEP_OPTION_DATE,
    data,
});

export const setNewOfferStepOptionDateStart = (data) => ({
    type: SET_NEW_OFFER_STEP_OPTION_DATE_START,
    data,
});

export const setNewOfferStepOptionDateEnd = (data) => ({
    type: SET_NEW_OFFER_STEP_OPTION_DATE_END,
    data,
});

export const setNewOfferStepOptionDateScore = (data) => ({
    type: SET_NEW_OFFER_STEP_OPTION_DATE_SCORE,
    data,
});

export const setNewOfferStepOptionDateDealbreaker = (data) => ({
    type: SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER,
    data,
});

export const setNewOfferSpecificSteps = (newOfferStep) => ({
    type: SET_NEW_OFFER_SPECIFIC_STEP,
    newOfferStep,
});

export const addNewOfferStep = (newOfferStep) => ({
    type: ADD_NEW_OFFER_STEP,
    newOfferStep,
});

export const removeNewOfferStep = (indexStep) => ({
    type: REMOVE_NEW_OFFER_STEP,
    indexStep,
});
