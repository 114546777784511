import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import {
    Button, DropdownMenu, DropdownToggle,














    FormGroup,
    Input, Label, Modal,

    ModalBody,
    ModalFooter, ModalHeader, Nav,



    NavItem,
    NavLink,

    UncontrolledButtonDropdown
} from "reactstrap";
import { setUserId } from "../../../redux/actions/administrationActions";
import AuthService from "../../../services/AuthService";
import { error, success, warning } from "../../../services/notify";









const Auth = new AuthService();

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUserId: (id) => dispatch(setUserId(id)),
    };
};

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            modal: false,
            previousPassword: "",
            newPassword: "",
            newPasswordBis: "",
        };
    }

    notifyWarning = (text) => (this.toastId = warning(text));
    notifyError = (text) => (this.toastId = error(text));
    notifySuccess = (text) => (this.toastId = success(text));

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    handleValueChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleUpdatePassword = () => {
        const { previousPassword, newPassword, newPasswordBis } = this.state;

        if (newPassword !== newPasswordBis) {
            return this.notifyWarning(
                "Les nouveaux mot de passe ne correspondent pas"
            );
        }

        Auth.fetch(`/users/${this.props.user._id}`, {
            method: "PATCH",
            body: JSON.stringify({
                previousPassword,
                newPassword,
            }),
        })
            .then((res) => {
                console.log(res);
                this.notifySuccess("Le mot de passe a été changé avec succès");
                this.toggle();
            })
            .catch((err) => {
                console.log(err);
                this.notifyError("Erreur lors du changement du mot de passe");
            });
    };

    handleEditProfile = () => {
        this.props.setUserId(this.props.user._id);
        this.props.history.push("/app/new/user");
    };

    handleDisconnect = () => {
        Auth.logout();
        this.props.history.push("/login");
    };

    render() {
        const {
            previousPassword,
            newPassword,
            newPasswordBis,
            modal,
        } = this.state;
        const companyName =
            this.props.user.company_id !== undefined
                ? this.props.user.company_id.name
                : "";

        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {this.props.user.login}
                                </div>
                                <div className="widget-subheading">
                                    {companyName}
                                </div>
                            </div>
                            <div className="widget-content-right">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                        color="link"
                                        className="p-0"
                                    >
                                        <FontAwesomeIcon
                                            className="ml-2 opacity-8"
                                            icon={faAngleDown}
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu
                                        right
                                        className="rm-pointers dropdown-menu-lg"
                                    >
                                        <PerfectScrollbar>
                                            <Nav vertical>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        onClick={
                                                            this
                                                                .handleEditProfile
                                                        }
                                                    >
                                                        Profil
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        onClick={this.toggle}
                                                    >
                                                        Changer le mot de passe
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        onClick={
                                                            this
                                                                .handleDisconnect
                                                        }
                                                    >
                                                        Déconnexion
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </PerfectScrollbar>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <Modal
                                isOpen={modal}
                                toggle={this.toggle}
                                className={this.props.className}
                            >
                                <ModalHeader toggle={this.toggle}>
                                    Changer le mot de passe
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="previousPassword">
                                            Ancien mot de passe
                                        </Label>
                                        <Input
                                            type="password"
                                            name="previousPassword"
                                            value={previousPassword}
                                            onChange={this.handleValueChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newPassword">
                                            Nouveau mot de passe
                                        </Label>
                                        <Input
                                            type="password"
                                            name="newPassword"
                                            value={newPassword}
                                            onChange={this.handleValueChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newPasswordBis">
                                            Répéter le mot de passe
                                        </Label>
                                        <Input
                                            type="password"
                                            name="newPasswordBis"
                                            value={newPasswordBis}
                                            onChange={this.handleValueChange}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="link" onClick={this.toggle}>
                                        Annuler
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={this.handleUpdatePassword}
                                    >
                                        Changer le mot de passe
                                    </Button>{" "}
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
