import produce from "immer";
import {
    SET_REQUIREMENTS,
    SET_SELECTED_OFFER
} from "../actions/fetchedDataActions";


const initialState = {
    requirements: [],
    selectedOffer: null,
};

export default produce((draft, action) => {
    switch (action.type) {
        case SET_REQUIREMENTS: {
            draft.requirements = action.requirements;
            return;
        }

        case SET_SELECTED_OFFER: {
            draft.selectedOffer = action.offer;
            return;
        }

        default: {
            return;
        }
    }
}, initialState);
