export const HolloNav = [
    {
        icon: "pe-7s-culture",
        label: "Clients",
        to: "/app/companies",
    },
    {
        icon: "pe-7s-box2",
        label: "Packages",
        to: "/app/deals",
    },
    {
        icon: "pe-7s-edit",
        label: "Configuration",
        content: [
            {
                label: "Templates Etapes",
                to: "/app/steps",
            },
            {
                label: "Templates Arborescences",
                to: "/app/trees",
            },
            {
                label: "Etapes Obligatoires",
                to: "/app/mandatorytrees",
            },
            {
                label: "Messages de Réengagement",
                to: "/app/messages",
            },
        ],
    },
];
