export const RESET_NEW_TREE = "RESET_NEW_TREE";
export const SET_NEW_TREE = "SET_NEW_TREE";

export const SET_NEW_TREE_ID = "SET_NEW_TREE_ID";
export const SET_NEW_TREE_NAME = "SET_NEW_TREE_NAME";

export const SET_NEW_TREE_REQUIREMENTS = "SET_NEW_TREE_REQUIREMENTS";
export const ADD_NEW_TREE_REQUIREMENT = "ADD_NEW_TREE_REQUIREMENT";
export const REMOVE_NEW_TREE_REQUIREMENT = "REMOVE_NEW_TREE_REQUIREMENT";

export const SET_NEW_TREE_SKILLS = "SET_NEW_TREE_SKILLS";
export const ADD_NEW_TREE_SKILL = "ADD_NEW_TREE_SKILL";
export const REMOVE_NEW_TREE_SKILL = "REMOVE_NEW_TREE_SKILL";

export const SET_NEW_TREE_STEP_NAME = "SET_NEW_TREE_STEP_NAME";
export const SET_NEW_TREE_STEP_SKILL = "SET_NEW_TREE_STEP_SKILL";
export const SET_NEW_TREE_STEP_REQUIREMENT = "SET_NEW_TREE_STEP_REQUIREMENT";
export const SET_NEW_TREE_STEP_CONDITION = "SET_NEW_TREE_STEP_CONDITION";
export const SET_NEW_TREE_STEP_TYPE = "SET_NEW_TREE_STEP_TYPE";
export const SET_NEW_TREE_STEP_SENTENCE = "SET_NEW_TREE_STEP_SENTENCE";
export const SET_NEW_TREE_STEP_END_CHAT = "SET_NEW_TREE_STEP_END_CHAT";
export const SET_NEW_TREE_STEP_WEIGHT = "SET_NEW_TREE_STEP_WEIGHT";

export const ADD_NEW_TREE_STEP_OPTION = "ADD_NEW_TREE_STEP_OPTION";
export const REMOVE_NEW_TREE_STEP_OPTION = "REMOVE_NEW_TREE_STEP_OPTION";
export const SET_NEW_TREE_STEP_OPTION_LABEL = "SET_NEW_TREE_STEP_OPTION_LABEL";
export const SET_NEW_TREE_STEP_OPTION_SCORE = "SET_NEW_TREE_STEP_OPTION_SCORE";
export const SET_NEW_TREE_STEP_OPTION_DEALBREAKER =
    "SET_NEW_TREE_STEP_OPTION_DEALBREAKER";

export const ADD_NEW_TREE_STEP_OPTION_NUMBER =
    "ADD_NEW_TREE_STEP_OPTION_NUMBER";
export const REMOVE_NEW_TREE_STEP_OPTION_NUMBER =
    "REMOVE_NEW_TREE_STEP_OPTION_NUMBER";
export const SET_NEW_TREE_STEP_OPTION_NUMBER_MIN =
    "SET_NEW_TREE_STEP_OPTION_NUMBER_MIN";
export const SET_NEW_TREE_STEP_OPTION_NUMBER_MAX =
    "SET_NEW_TREE_STEP_OPTION_NUMBER_MAX";
export const SET_NEW_TREE_STEP_OPTION_NUMBER_SCORE =
    "SET_NEW_TREE_STEP_OPTION_NUMBER_SCORE";
export const SET_NEW_TREE_STEP_OPTION_NUMBER_DEALBREAKER =
    "SET_NEW_TREE_STEP_OPTION_NUMBER_DEALBREAKER";

export const ADD_NEW_TREE_STEP_OPTION_DATE = "ADD_NEW_TREE_STEP_OPTION_DATE";
export const REMOVE_NEW_TREE_STEP_OPTION_DATE =
    "REMOVE_NEW_TREE_STEP_OPTION_DATE";
export const SET_NEW_TREE_STEP_OPTION_DATE_START =
    "SET_NEW_TREE_STEP_OPTION_DATE_START";
export const SET_NEW_TREE_STEP_OPTION_DATE_END =
    "SET_NEW_TREE_STEP_OPTION_DATE_END";
export const SET_NEW_TREE_STEP_OPTION_DATE_SCORE =
    "SET_NEW_TREE_STEP_OPTION_DATE_SCORE";
export const SET_NEW_TREE_STEP_OPTION_DATE_DEALBREAKER =
    "SET_NEW_TREE_STEP_OPTION_DATE_DEALBREAKER";

export const SET_NEW_TREE_STEPS = "SET_NEW_TREE_STEPS";
export const SET_NEW_TREE_SPECIFIC_STEP = "SET_NEW_TREE_SPECIFIC_STEP";
export const ADD_NEW_TREE_STEP = "ADD_NEW_TREE_STEP";
export const REMOVE_NEW_TREE_STEP = "REMOVE_NEW_TREE_STEP";

export const resetNewTree = () => ({
    type: RESET_NEW_TREE,
});

export const setNewTree = (tree) => ({
    type: SET_NEW_TREE,
    tree,
});

export const setNewTreeId = (id) => ({
    type: SET_NEW_TREE_ID,
    id,
});

export const setNewTreeName = (name) => ({
    type: SET_NEW_TREE_NAME,
    name,
});

export const setNewTreeRequirements = (requirements) => ({
    type: SET_NEW_TREE_REQUIREMENTS,
    requirements,
});

export const addNewTreeRequirement = (data) => ({
    type: ADD_NEW_TREE_REQUIREMENT,
    data,
});

export const removeNewTreeRequirement = (requirementId) => ({
    type: REMOVE_NEW_TREE_REQUIREMENT,
    requirementId,
});

export const setNewTreeSkills = (skills) => ({
    type: SET_NEW_TREE_SKILLS,
    skills,
});

export const addNewTreeSkill = (data) => ({
    type: ADD_NEW_TREE_SKILL,
    data,
});

export const removeNewTreeSkill = (skillId) => ({
    type: REMOVE_NEW_TREE_SKILL,
    skillId,
});

export const setNewTreeStepName = (data) => ({
    type: SET_NEW_TREE_STEP_NAME,
    data,
});

export const setNewTreeStepSkill = (data) => ({
    type: SET_NEW_TREE_STEP_SKILL,
    data,
});

export const setNewTreeStepRequirement = (data) => ({
    type: SET_NEW_TREE_STEP_REQUIREMENT,
    data,
});

export const setNewTreeStepCondition = (data) => ({
    type: SET_NEW_TREE_STEP_CONDITION,
    data,
});

export const setNewTreeStepType = (data) => ({
    type: SET_NEW_TREE_STEP_TYPE,
    data,
});

export const setNewTreeStepSentence = (data) => ({
    type: SET_NEW_TREE_STEP_SENTENCE,
    data,
});

export const setNewTreeStepEndChat = (data) => ({
    type: SET_NEW_TREE_STEP_END_CHAT,
    data,
});

export const setNewTreeStepWeight = (data) => ({
    type: SET_NEW_TREE_STEP_WEIGHT,
    data,
});

export const addNewTreeStepOption = (stepId) => ({
    type: ADD_NEW_TREE_STEP_OPTION,
    stepId,
});

export const removeNewTreeStepOption = (data) => ({
    type: REMOVE_NEW_TREE_STEP_OPTION,
    data,
});

export const setNewTreeStepOptionLabel = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_LABEL,
    data,
});

export const setNewTreeStepOptionScore = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_SCORE,
    data,
});

export const setNewTreeStepOptionDealbreaker = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_DEALBREAKER,
    data,
});

export const addNewTreeStepOptionNumber = (stepId) => ({
    type: ADD_NEW_TREE_STEP_OPTION_NUMBER,
    stepId,
});

export const removeNewTreeStepOptionNumber = (data) => ({
    type: REMOVE_NEW_TREE_STEP_OPTION_NUMBER,
    data,
});

export const setNewTreeStepOptionNumberMin = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_NUMBER_MIN,
    data,
});

export const setNewTreeStepOptionNumberMax = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_NUMBER_MAX,
    data,
});

export const setNewTreeStepOptionNumberScore = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_NUMBER_SCORE,
    data,
});

export const setNewTreeStepOptionNumberDealbreaker = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_NUMBER_DEALBREAKER,
    data,
});

export const setNewTreeSteps = (steps) => ({
    type: SET_NEW_TREE_STEPS,
    steps,
});

export const addNewTreeStepOptionDate = (stepId) => ({
    type: ADD_NEW_TREE_STEP_OPTION_DATE,
    stepId,
});

export const removeNewTreeStepOptionDate = (data) => ({
    type: REMOVE_NEW_TREE_STEP_OPTION_DATE,
    data,
});

export const setNewTreeStepOptionDateStart = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_DATE_START,
    data,
});

export const setNewTreeStepOptionDateEnd = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_DATE_END,
    data,
});

export const setNewTreeStepOptionDateScore = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_DATE_SCORE,
    data,
});

export const setNewTreeStepOptionDateDealbreaker = (data) => ({
    type: SET_NEW_TREE_STEP_OPTION_DATE_DEALBREAKER,
    data,
});

export const setNewTreeSpecificSteps = (step) => ({
    type: SET_NEW_TREE_SPECIFIC_STEP,
    step,
});

export const addNewTreeStep = (step) => ({
    type: ADD_NEW_TREE_STEP,
    step,
});

export const removeNewTreeStep = (stepId) => ({
    type: REMOVE_NEW_TREE_STEP,
    stepId,
});
