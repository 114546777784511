import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { setUser } from "../../redux/actions/userActions";
import AuthService from "../../services/AuthService";
import { error } from "../../services/notify";


const Auth = new AuthService();

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user)),
    };
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
        };
    }

    handleChange = (e) => {
        if (e.target.name === "login") {
            this.setState({ login: e.target.value });
        }
        if (e.target.name === "password") {
            this.setState({ password: e.target.value });
        }
    };

    notifyError = (text) => (this.toastId = error(text));

    handleLogin = () => {
        const { login, password } = this.state;

        Auth.login(login, password)
            .then((res) => {
                const { user } = res;
                this.props.setUser(user);
                this.props.history.push("/");
            })
            .catch((err) => {
                let errString = "Problème lors de la connexion";
                if (err.message === "Invalid login") {
                    errString =
                        "Erreur lors de l'identification, vérifiez l'identifiant et le mot de passe";
                } else if (
                    err.message ===
                    "Too many failed attempts. The account is locked, please try to contact an administrator"
                ) {
                    errString =
                        "Suite à un nombre de tentatives infructueuses trop grand, le compte utilisateur a été bloqué, essayez de contacter un administrateur";
                } else {
                    errString = err.message;
                }
                this.notifyError(errString);
            });
    };

    render() {
        return (
            <Fragment>
                <div className="h-100 bg-plum-plate bg-animation">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="8" className="mx-auto app-login-box">
                            <div className="app-logo-inverse mx-auto mb-3" />

                            <div className="modal-dialog w-100 mx-auto">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="h5 modal-title text-center">
                                            <h4 className="mt-2">
                                                <div>Bonjour,</div>
                                                <span>
                                                    Connectez vous à votre
                                                    compte ci dessous.
                                                </span>
                                            </h4>
                                        </div>
                                        <Form>
                                            <Row form>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="login"
                                                            id="login"
                                                            placeholder="Identifiant"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12}>
                                                    <FormGroup>
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Mot de passe"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                        {false && (
                                            <>
                                                <div className="divider" />
                                                <h6 className="mb-0">
                                                    Pas de compte ?{" "}
                                                    <a
                                                        href="/register"
                                                        className="text-primary"
                                                    >
                                                        Créer mon compte
                                                    </a>
                                                </h6>
                                            </>
                                        )}
                                    </div>
                                    <div className="modal-footer clearfix">
                                        {false && (
                                            <div className="float-left">
                                                <a
                                                    href="/forgot"
                                                    className="btn-lg btn btn-link"
                                                >
                                                    Mot de passe oublié
                                                </a>
                                            </div>
                                        )}
                                        <div className="float-right">
                                            <Button
                                                color="primary"
                                                size="lg"
                                                onClick={this.handleLogin}
                                            >
                                                Connexion
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(Login);
