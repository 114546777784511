import React, { Fragment } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
// Layout

const ForgotPasswordBoxed = ({ match }) => (
    <Fragment>
        <div className="h-100 bg-plum-plate bg-animation">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="6" className="mx-auto app-login-box">
                    <div className="app-logo-inverse mx-auto mb-3" />

                    <div className="modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="h5 modal-title">
                                    Vous avez oublié votre mot de passe ?
                                    <h6 className="mt-1 mb-0 opacity-8">
                                        <span>
                                            Entrez votre adresse mail pour le
                                            réinitialiser
                                        </span>
                                    </h6>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">
                                                        Email
                                                    </Label>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className="divider" />
                                <h6 className="mb-0">
                                    <a href="/login" className="text-primary">
                                        Se connecter avec un compte existant
                                    </a>
                                </h6>
                            </div>
                            <div className="modal-footer clearfix">
                                <div className="float-right">
                                    <Button color="primary" size="lg">
                                        Réinitialiser mot de passe
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    </Fragment>
);

export default ForgotPasswordBoxed;
