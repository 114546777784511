import cx from "classnames";
import React, { Fragment } from "react";
import ResizeDetector from "react-resize-detector";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppMain from "../../Layout/AppMain";
import ForgotPassword from "../../routes/ForgotPassword";
import Login from "../../routes/Login";
import Register from "../../routes/Register";
import "./Main.css";






class Main extends React.Component {
    render() {
        const colorScheme = "white";
        const enableFixedHeader = true;
        const enableFixedSidebar = true;
        const enableFixedFooter = true;
        const enableClosedSidebar = false;
        const closedSmallerSidebar = false;
        const enableMobileMenu = false;
        const enablePageTabsAlt = false;

        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <div
                            className={cx(
                                "app-container app-theme-" + colorScheme,
                                { "fixed-header": enableFixedHeader },
                                {
                                    "fixed-sidebar":
                                        enableFixedSidebar || width < 1250,
                                },
                                { "fixed-footer": enableFixedFooter },
                                {
                                    "closed-sidebar":
                                        enableClosedSidebar || width < 1250,
                                },
                                {
                                    "closed-sidebar-mobile":
                                        closedSmallerSidebar || width < 1250,
                                },
                                { "sidebar-mobile-open": enableMobileMenu },
                                { "body-tabs-shadow-btn": enablePageTabsAlt }
                            )}
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => <Redirect to="/app" />}
                                />
                                <Route path="/app" component={AppMain} />
                                <Route exact path="/login" component={Login} />
                                <Route
                                    exact
                                    path="/register"
                                    component={Register}
                                />
                                <Route
                                    exact
                                    path="/forgot"
                                    component={ForgotPassword}
                                />
                                <Route
                                    path="*"
                                    render={() => <Redirect to="/app" />}
                                />
                            </Switch>
                            <ToastContainer />
                        </div>
                    </Fragment>
                )}
            />
        );
    }
}

export default withRouter(Main);
