import React, { Component, Fragment } from "react";
import MetisMenu from "react-metismenu";
import RouterLink from "react-metismenu-router-link";
import { withRouter } from "react-router-dom";
import { HolloNav } from "./NavItems";

class Nav extends Component {
    state = {};

    render() {
        return (
            <Fragment>
                <MetisMenu
                    content={HolloNav}
                    LinkComponent={RouterLink}
                    activeLinkFromLocation
                    className="vertical-nav-menu"
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                />
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

export default withRouter(Nav);
