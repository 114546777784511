export const SET_ENABLE_CLOSED_SIDEBAR = "SET_ENABLE_CLOSED_SIDEBAR";
export const SET_ENABLE_MOBILE_MENU = "SET_ENABLE_MOBILE_MENU";

export const RESET_CONVERSATIONS_UI = "RESET_CONVERSATIONS_UI";
export const SET_CONVERSATIONS_VIEW = "SET_CONVERSATIONS_VIEW";
export const SET_CONVERSATIONS_CURSOR = "SET_CONVERSATIONS_CURSOR";
export const SET_CONVERSATIONS_SORT = "SET_CONVERSATIONS_SORT";

export const RESET_OFFERS_UI = "RESET_OFFERS_UI";
export const SET_OFFERS_NAME = "SET_OFFERS_NAME";
export const SET_OFFERS_CURSOR = "SET_OFFERS_CURSOR";
export const SET_OFFERS_SORT = "SET_OFFERS_SORT";
export const SET_OFFERS_ARCHIVED = "SET_OFFERS_ARCHIVED";

export const SET_CANDIDATES_TEXT_SEARCH = "SET_CANDIDATES_TEXT_SEARCH";
export const SET_CANDIDATES_CURSOR = "SET_CANDIDATES_CURSOR";
export const SET_CANDIDATES_SORT = "SET_CANDIDATES_SORT";

export const setEnableClosedSidebar = (enableClosedSidebar) => ({
    type: SET_ENABLE_CLOSED_SIDEBAR,
    enableClosedSidebar,
});

export const setEnableMobileMenu = (enableMobileMenu) => ({
    type: SET_ENABLE_MOBILE_MENU,
    enableMobileMenu,
});

export const resetConversationsUi = () => ({
    type: RESET_CONVERSATIONS_UI,
});

export const setConversationsView = (view) => ({
    type: SET_CONVERSATIONS_VIEW,
    view,
});

export const setConversationsCursor = (cursor) => ({
    type: SET_CONVERSATIONS_CURSOR,
    cursor,
});

export const setConversationsSort = (data) => ({
    type: SET_CONVERSATIONS_SORT,
    data,
});

export const resetOffersUi = () => ({
    type: RESET_OFFERS_UI,
});

export const setOffersName = (name) => ({
    type: SET_OFFERS_NAME,
    name,
});

export const setOffersCursor = (cursor) => ({
    type: SET_OFFERS_CURSOR,
    cursor,
});

export const setOffersSort = (data) => ({
    type: SET_OFFERS_SORT,
    data,
});

export const setOffersArchived = (offersDisplayArchived) => ({
    type: SET_OFFERS_ARCHIVED,
    offersDisplayArchived,
});

export const setCandidatesTextSearch = (text) => ({
    type: SET_CANDIDATES_TEXT_SEARCH,
    text,
});

export const setCandidatesCursor = (cursor) => ({
    type: SET_CANDIDATES_CURSOR,
    cursor,
});

export const setCandidatesSort = (data) => ({
    type: SET_CANDIDATES_SORT,
    data,
});
