import React, { Component } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/userActions";
import AuthService from "./AuthService";

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();

    const mapStateToProps = (state) => {
        return {
            user: state.user,
        };
    };

    const mapDispatchToProps = (dispatch) => {
        return {
            setUser: (user) => dispatch(setUser(user)),
        };
    };

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        class AuthWrapped extends Component {
            componentWillMount() {
                if (!Auth.loggedIn()) {
                    this.props.history.push("/login");
                } else {
                    try {
                        const profile = Auth.getProfile();
                        if (this.props.user._id === undefined) {
                            Auth.fetch(`/users/${profile.sub}`).then((res) => {
                                this.props.setUser(res);
                            });
                        }
                    } catch (err) {
                        console.log(err);
                        Auth.logout();
                        this.props.history.replace("/login");
                    }
                }
            }

            render() {
                if (this.props.user._id !== undefined) {
                    return (
                        <AuthComponent
                            history={this.props.history}
                            user={this.props.user}
                        />
                    );
                } else {
                    return null;
                }
            }
        }
    );
}
