export const RESET_NEW_MESSAGE = "RESET_NEW_MESSAGE";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";

export const SET_NEW_MESSAGE_NAME = "SET_NEW_MESSAGE_NAME";
export const SET_NEW_MESSAGE_TEXT = "SET_NEW_MESSAGE_TEXT";
export const SET_NEW_MESSAGE_SUBJECT = "SET_NEW_MESSAGE_SUBJECT";
export const SET_NEW_MESSAGE_FORMAT = "SET_NEW_MESSAGE_FORMAT";
export const SET_NEW_MESSAGE_TYPE = "SET_NEW_MESSAGE_TYPE";

export const resetNewMessage = () => ({
    type: RESET_NEW_MESSAGE,
});

export const setNewMessage = (message) => ({
    type: SET_NEW_MESSAGE,
    message,
});

export const setNewMessageName = (name) => ({
    type: SET_NEW_MESSAGE_NAME,
    name,
});

export const setNewMessageText = (text) => ({
    type: SET_NEW_MESSAGE_TEXT,
    text,
});

export const setNewMessageSubject = (subject) => ({
    type: SET_NEW_MESSAGE_SUBJECT,
    subject,
});

export const setNewMessageFormat = (format) => ({
    type: SET_NEW_MESSAGE_FORMAT,
    format,
});

export const setNewMessageType = (messageType) => ({
    type: SET_NEW_MESSAGE_TYPE,
    messageType,
});
