import decode from "jwt-decode";

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = process.env.REACT_APP_API_URL || "http://localhost:3000"; // API server domain
        // this.domain = 'http://3.121.136.110' // API server domain
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    login(login, password) {
        // Get a token from api server using the fetch api
        return (
            this.fetch("/authentication", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    login,
                    password,
                    strategy: "local",
                }),
            })
                // .then(res => res.json())
                .then((res) => {
                    const { permissions } = res.user;

                    if (!permissions.includes("admin_hollo")) {
                        throw new Error(
                            "Vous n'avez pas les autorisations nécessaires pour vous connecter"
                        );
                    }

                    if (res.accessToken === undefined) return;
                    this.setToken(res.accessToken);
                    return Promise.resolve(res);
                })
        );
    }

    signup(login, firstname, lastname, email, password) {
        return this.fetch("/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                login,
                firstname,
                lastname,
                email,
                password,
            }),
        }).then((res) => {
            this.setToken(res.accessToken); // Setting the token in localStorage
            return Promise.resolve(res);
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                // Checking if token is expired. N
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem("id_token", idToken);
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem("id_token");
    }

    logout() {
        // Clear user token and profile data from localStorage
        return this.fetch("/authentication", {
            method: "DELETE",
        }).then((res) => {
            localStorage.removeItem("id_token");
        });
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers["Authorization"] = "Bearer " + this.getToken();
        }

        return fetch(`${this.domain}${url}`, {
            headers,
            ...options,
        })
            .then(this._checkStatus)
            .then((response) => response.json())
            .then((res) => {
                if (res.newAccessToken) {
                    this.setToken(res.newAccessToken);
                    return res.data;
                }
                return res;
            });
    }

    fetchWithoutHeaders(url, options) {
        const headers = {};
        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers["Authorization"] = "Bearer " + this.getToken();
        }

        return fetch(`${this.domain}${url}`, {
            headers,
            ...options,
        })
            .then(this._checkStatus)
            .then((response) => response.json())
            .then((res) => {
                if (res.newAccessToken) {
                    this.setToken(res.newAccessToken);
                    return res.data;
                }
                return res;
            });
    }

    fetchCsv(url, options) {
        const headers = {
            "Content-Type": "text/csv",
        };
        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers["Authorization"] = "Bearer " + this.getToken();
        }

        return fetch(`${this.domain}${url}`, {
            headers,
            ...options,
        }).then(this._checkStatus);
        // .then(res => {
        //     if (res.newAccessToken) {
        //         this.setToken(res.newAccessToken);
        //         return res.data;
        //     }
        //     return res;
        // })
    }

    async _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            // Success status lies between 200 to 300
            return response;
        } else {
            throw await response.json();
            // var error = new Error(response.statusText)
            // error.response = response
            // throw error
        }
    }
}
