import produce from "immer";
import { mongoObjectId } from "../../services/utils";
import {
    ADD_NEW_STEP_OPTION,




    ADD_NEW_STEP_OPTION_NUMBER, REMOVE_NEW_STEP_OPTION,




    REMOVE_NEW_STEP_OPTION_NUMBER, RESET_NEW_STEP,
    SET_NEW_STEP,
    SET_NEW_STEP_NAME,









    SET_NEW_STEP_OPTION_DEALBREAKER, SET_NEW_STEP_OPTION_LABEL,







    SET_NEW_STEP_OPTION_NUMBER_DEALBREAKER, SET_NEW_STEP_OPTION_NUMBER_MAX, SET_NEW_STEP_OPTION_NUMBER_MIN,

    SET_NEW_STEP_OPTION_NUMBER_SCORE, SET_NEW_STEP_OPTION_SCORE, SET_NEW_STEP_REQUIREMENT,

    SET_NEW_STEP_SENTENCE, SET_NEW_STEP_SKILL,

    SET_NEW_STEP_TYPE,

    SET_NEW_STEP_WEIGHT
} from "../actions/newStepActions";


const initialState = {
    id: null,
    name: "",
    skill: "",
    skill_id: null,
    requirement: "",
    requirement_id: null,
    variable: "",
    type: "textfield",
    sentence: "",
    weight: 2,
    options: [],
    numberScores: [],
    level: 0,
};

export default produce((draft, action) => {
    switch (action.type) {
        case RESET_NEW_STEP:
            return initialState;

        case SET_NEW_STEP: {
            const { step } = action;

            draft.id = step._id !== undefined ? step._id : initialState.id;
            draft.name =
                step.name !== undefined ? step.name : initialState.name;
            draft.skill =
                step.skill !== undefined ? step.skill : initialState.skill;
            draft.skill_id =
                step.skill_id !== undefined
                    ? step.skill_id
                    : initialState.skill_id;
            draft.requirement =
                step.requirement !== undefined
                    ? step.requirement
                    : initialState.requirement;
            draft.requirement_id =
                step.requirement_id !== undefined
                    ? step.requirement_id
                    : initialState.requirement_id;
            draft.variable =
                step.variable !== undefined
                    ? step.variable
                    : initialState.variable;
            draft.type =
                step.type !== undefined ? step.type : initialState.type;
            draft.sentence =
                step.sentence !== undefined
                    ? step.sentence
                    : initialState.sentence;
            draft.weight =
                step.weight !== undefined ? step.weight : initialState.weight;
            draft.options =
                step.options !== undefined
                    ? step.options
                    : initialState.options;
            draft.numberScores =
                step.numberScores !== undefined
                    ? step.numberScores
                    : initialState.numberScores;
            draft.level = 0;

            return;
        }

        case SET_NEW_STEP_NAME: {
            draft.name = action.name;
            draft.variable = action.name;
            return;
        }

        case SET_NEW_STEP_SKILL: {
            draft.skill_id = action.skillId;
            draft.requirement_id = initialState.requirement_id;
            return;
        }

        case SET_NEW_STEP_REQUIREMENT: {
            draft.requirement_id = action.requirementId;
            draft.skill_id = initialState.skill_id;
            return;
        }

        case SET_NEW_STEP_TYPE: {
            draft.type = action.stepType;
            return;
        }

        case SET_NEW_STEP_SENTENCE: {
            draft.sentence = action.sentence;
            return;
        }

        case SET_NEW_STEP_WEIGHT: {
            draft.weight = action.weight;
            return;
        }

        case ADD_NEW_STEP_OPTION: {
            draft.options.push({
                _id: mongoObjectId(),
                label: "",
                value: "",
                score: 0,
                dealbreaker: false,
            });
            return;
        }

        case REMOVE_NEW_STEP_OPTION: {
            draft.options.splice(action.index, 1);
            return;
        }

        case SET_NEW_STEP_OPTION_LABEL: {
            draft.options[action.data.optionIndex].label = action.data.label;
            draft.options[action.data.optionIndex].value = action.data.label;
            return;
        }

        case SET_NEW_STEP_OPTION_SCORE: {
            draft.options[action.data.optionIndex].score = action.data.score;
            return;
        }

        case SET_NEW_STEP_OPTION_DEALBREAKER: {
            draft.options[action.data.optionIndex].dealbreaker =
                action.data.dealbreaker;
            return;
        }

        case ADD_NEW_STEP_OPTION_NUMBER: {
            draft.numberScores.push({
                minValue: 0,
                maxValue: 0,
                score: 0,
                dealbreaker: false,
            });
            return;
        }

        case REMOVE_NEW_STEP_OPTION_NUMBER: {
            draft.numberScores.splice(action.index, 1);
            return;
        }

        case SET_NEW_STEP_OPTION_NUMBER_MIN: {
            draft.numberScores[action.data.scoreIndex].minValue =
                action.data.min;
            return;
        }

        case SET_NEW_STEP_OPTION_NUMBER_MAX: {
            draft.numberScores[action.data.scoreIndex].maxValue =
                action.data.max;
            return;
        }

        case SET_NEW_STEP_OPTION_NUMBER_SCORE: {
            draft.numberScores[action.data.scoreIndex].score =
                action.data.score;
            return;
        }

        case SET_NEW_STEP_OPTION_NUMBER_DEALBREAKER: {
            draft.numberScores[action.data.scoreIndex].dealbreaker =
                action.data.dealbreaker;
            return;
        }

        default: {
            return;
        }
    }
}, initialState);
