import cx from "classnames";
import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";





class Header extends React.Component {
    render() {
        let headerBackgroundColor = "",
            enableMobileMenuSmall = "",
            enableHeaderShadow = true;

        return (
            <Fragment>
                <CSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, {
                        "header-shadow": enableHeaderShadow,
                    })}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                    <HeaderLogo />

                    <div
                        className={cx("app-header__content", {
                            "header-mobile-open": enableMobileMenuSmall,
                        })}
                    >
                        <div className="app-header-right">
                            <UserBox {...this.props} />
                        </div>
                    </div>
                </CSSTransitionGroup>
            </Fragment>
        );
    }
}

export default Header;
