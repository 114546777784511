import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './routes/Main/Main';
import store from './redux/store';
import { Provider } from 'react-redux';

const stripePromise = loadStripe("pk_test_51HEYDBJVopXH6lLGQ3PLFRMY48OcAGjpUdVzIFBSLEAMJJ84gtRp3ShmwPQqu1p9fughLQD9NC2VqNHxN5kqNjxS00a1uj5yOq");

const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Elements stripe={stripePromise}>
                    <Component />
                </Elements>
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./routes/Main/Main', () => {
        const NextApp = require('./routes/Main/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

