export const SET_REQUIREMENTS = "SET_REQUIREMENTS";
export const SET_SELECTED_OFFER = "SET_SELECTED_OFFER";

export const setRequirements = (requirements) => ({
    type: SET_REQUIREMENTS,
    requirements,
});

export const setSelectedOffer = (offer) => ({
    type: SET_SELECTED_OFFER,
    offer,
});
