import React, { lazy, Suspense } from "react";
import Loader from "react-loaders";
import { Redirect, Route } from "react-router-dom";
import DisconnectedComponent from "../../components/DisconnectedComponent";
import AppFooter from "../../Layout/AppFooter/";
// Layout
import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import withAuth from "../../services/withAuth";

const CreateOffer = lazy(() => import("../../routes/CreateOffer"));
const CreateTeam = lazy(() => import("../../routes/CreateTeam"));
const CreateUser = lazy(() => import("../../routes/CreateUser"));
const CreateProcessStatus = lazy(() =>
    import("../../routes/CreateProcessStatus")
);
const CreateTree = lazy(() => import("../../routes/CreateTree"));
const CreateMandatoryTree = lazy(() =>
    import("../../routes/CreateMandatoryTree")
);
const CreateStep = lazy(() => import("../../routes/CreateStep"));
const CreateReengagementMessage = lazy(() =>
    import("../../routes/CreateReengagementMessage")
);
const Offers = lazy(() => import("../../routes/Offers"));
const Steps = lazy(() => import("../../routes/Steps"));
const Trees = lazy(() => import("../../routes/Trees"));
const MandatoryTrees = lazy(() => import("../../routes/MandatoryTrees"));
const ReengagementMessages = lazy(() =>
    import("../../routes/ReengagementMessages")
);
const Teams = lazy(() => import("../../routes/Teams"));
const Users = lazy(() => import("../../routes/Users"));
const Conversations = lazy(() => import("../../routes/Conversations"));
const Conversation = lazy(() => import("../../routes/Conversation"));
const Appearance = lazy(() => import("../../routes/Appearance"));
const Ats = lazy(() => import("../../routes/Ats"));
const ProcessStatus = lazy(() => import("../../routes/ProcessStatus"));
const Platforms = lazy(() => import("../../routes/Platforms"));
const Companies = lazy(() => import("../../routes/Companies"));
const Company = lazy(() => import("../../routes/Company"));
const CreateCompany = lazy(() => import("../../routes/CreateCompany"));
const Sentences = lazy(() => import("../../routes/Sentences"));
const Candidates = lazy(() => import("../../routes/Candidates"));
const Candidate = lazy(() => import("../../routes/Candidate"));
const Billing = lazy(() => import("../../routes/Billing"));
const Deals = lazy(() => import("../../routes/Deals"));
const CreateDeal = lazy(() => import("../../routes/CreateDeal"));
const Usage = lazy(() => import("../../routes/Usage"));

const AppMain = (props) => {
    return (
        <>
            <AppHeader {...props} />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <DisconnectedComponent />

                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <div className="text-center">
                                            <Loader type="ball-grid-cy" />
                                        </div>
                                        <h6 className="mt-3">Chargement</h6>
                                    </div>
                                </div>
                            }
                        >
                            <Route
                                exact
                                path="/app/new/offer"
                                component={CreateOffer}
                            />
                            <Route
                                exact
                                path="/app/new/team"
                                component={CreateTeam}
                            />
                            <Route
                                exact
                                path="/app/new/user"
                                component={CreateUser}
                            />
                            <Route
                                exact
                                path="/app/new/processstatus"
                                component={CreateProcessStatus}
                            />
                            <Route
                                exact
                                path="/app/new/tree"
                                component={CreateTree}
                            />
                            <Route
                                exact
                                path="/app/new/mandatorytree"
                                component={CreateMandatoryTree}
                            />
                            <Route
                                exact
                                path="/app/new/step"
                                component={CreateStep}
                            />
                            <Route
                                exact
                                path="/app/new/message"
                                component={CreateReengagementMessage}
                            />

                            <Route
                                exact
                                path="/app/companies/:companyId/new/offer"
                                component={CreateOffer}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/team"
                                component={CreateTeam}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/user"
                                component={CreateUser}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/processstatus"
                                component={CreateProcessStatus}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/tree"
                                component={CreateTree}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/mandatorytree"
                                component={CreateMandatoryTree}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/step"
                                component={CreateStep}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/new/message"
                                component={CreateReengagementMessage}
                            />

                            <Route
                                exact
                                path="/app/companies/:companyId/offers"
                                component={Offers}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/offers/:id"
                                component={Conversations}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/conversations/:id"
                                component={Conversation}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/candidates"
                                component={Candidates}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/candidates/:id"
                                component={Candidates}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/steps"
                                component={Steps}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/trees"
                                component={Trees}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/teams"
                                component={Teams}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/users"
                                component={Users}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/appearance"
                                component={Appearance}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/ats"
                                component={Ats}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/mandatorytrees"
                                component={MandatoryTrees}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/processstatus"
                                component={ProcessStatus}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/platforms"
                                component={Platforms}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/messages"
                                component={ReengagementMessages}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/sentences"
                                component={Sentences}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/billing"
                                component={Billing}
                            />
                            <Route
                                exact
                                path="/app/companies/:companyId/usage"
                                component={Usage}
                            />

                            <Route
                                exact
                                path="/app/candidates/:id"
                                component={Candidate}
                            />
                            <Route exact path="/app/steps" component={Steps} />
                            <Route exact path="/app/trees" component={Trees} />
                            <Route
                                exact
                                path="/app/offers/:id"
                                component={Conversations}
                            />
                            <Route
                                exact
                                path="/app/conversations/:id"
                                component={Conversation}
                            />
                            <Route
                                exact
                                path="/app/mandatorytrees"
                                component={MandatoryTrees}
                            />
                            <Route
                                exact
                                path="/app/messages"
                                component={ReengagementMessages}
                            />
                            <Route
                                exact
                                path="/app/companies"
                                component={Companies}
                            />
                            <Route
                                exact
                                path="/app/companies/:id"
                                component={Company}
                            />
                            <Route
                                exact
                                path="/app/new/company"
                                component={CreateCompany}
                            />

                            <Route
                                exact
                                path="/app/deals"
                                component={Deals}
                            />
                            <Route
                                exact
                                path="/app/new/deal"
                                component={CreateDeal}
                            />
                            <Route
                                exact
                                path="/app/new/deal/:dealId"
                                component={CreateDeal}
                            />
                        </Suspense>

                        <Route
                            exact
                            path="/app"
                            render={() => <Redirect to="/app/companies" />}
                        />
                    </div>
                    <AppFooter />
                </div>
            </div>
        </>
    );
};

export default withAuth(AppMain);
