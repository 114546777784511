import React, { Fragment } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";

// Layout

const RegisterBoxed = ({ match }) => (
    <Fragment>
        <div className="h-100 bg-premium-dark">
            <div className="d-flex h-100 justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">
                    <div className="app-logo-inverse mx-auto mb-3" />

                    <div className="modal-dialog w-100">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h5 className="modal-title">
                                    <h4 className="mt-2">
                                        <div>Bienvenue,</div>
                                        <span>
                                            Cela prend seulement{" "}
                                            <span className="text-success">
                                                quelques secondes
                                            </span>{" "}
                                            pour créer votre compte
                                        </span>
                                    </h4>
                                </h5>
                                <Row className="divider" />
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="login"
                                                id="login"
                                                placeholder="Identifiant"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="firstname"
                                                id="firstname"
                                                placeholder="Prénom"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                name="lastname"
                                                id="lastname"
                                                placeholder="Nom"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder="Mot de passe"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input
                                                type="password"
                                                name="passwordrep"
                                                id="passwordrep"
                                                placeholder="Répéter le mot de passe"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup className="mt-3" check>
                                    <Input
                                        type="checkbox"
                                        name="check"
                                        id="exampleCheck"
                                    />
                                    <Label for="exampleCheck" check>
                                        J'accepte les{" "}
                                        <a href="https://colorlib.com/">
                                            Conditions d'utilisation
                                        </a>
                                        .
                                    </Label>
                                </FormGroup>
                                <Row className="divider" />
                                <h6 className="mb-0">
                                    Déja un compte?{" "}
                                    <a href="/login" className="text-primary">
                                        Se connecter
                                    </a>{" "}
                                    |{" "}
                                    <a href="/forgot" className="text-primary">
                                        Mot de passe oublié
                                    </a>
                                </h6>
                            </div>
                            <div className="modal-footer d-block text-center">
                                <Button
                                    color="primary"
                                    className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                    size="lg"
                                >
                                    Créer mon compte
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    </Fragment>
);

export default RegisterBoxed;
