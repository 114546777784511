import { combineReducers, compose, createStore } from "redux";
import administration from "./reducers/Administration";
import fetchedData from "./reducers/FetchedData";
import newMessage from "./reducers/NewMessage";
import newOffer from "./reducers/NewOffer";
import newStep from "./reducers/NewStep";
import newTree from "./reducers/NewTree";
import ui from "./reducers/Ui";
import user from "./reducers/User";

const store = createStore(
    combineReducers({
        newOffer,
        newStep,
        newTree,
        newMessage,
        user,
        ui,
        fetchedData,
        administration,
    }),
    {},
    compose(
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);

export default store;
