import React, { Fragment } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";


class AppFooter extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="app-footer">
                    <div className="app-footer__inner">
                        <div className="app-footer-right">
                            <Nav className="header-menu">
                                <NavItem>
                                    <NavLink href="mailto:andy@hollo.io?subject=Support">
                                        Support
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default AppFooter;
