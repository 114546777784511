import produce from "immer";
import {
    RESET_CONVERSATIONS_UI,



    RESET_OFFERS_UI,





    SET_CANDIDATES_CURSOR,
    SET_CANDIDATES_SORT, SET_CANDIDATES_TEXT_SEARCH, SET_CONVERSATIONS_CURSOR,
    SET_CONVERSATIONS_SORT, SET_CONVERSATIONS_VIEW, SET_ENABLE_CLOSED_SIDEBAR,
    SET_ENABLE_MOBILE_MENU,








    SET_OFFERS_ARCHIVED, SET_OFFERS_CURSOR, SET_OFFERS_NAME,

    SET_OFFERS_SORT
} from "../actions/uiActions";


const initialState = {
    enableClosedSidebar: false,
    enableMobileMenu: false,
    enableMobileMenuSmall: false,
    conversationsView: "notClassified",
    conversationsCursor: 0,
    conversationsSortField: "createdAt",
    conversationsSortOrder: -1,
    offersName: "",
    offersCursor: 0,
    offersSortField: "createdAt",
    offersSortOrder: -1,
    offersDisplayArchived: false,
    candidatesTextSearch: "",
    candidatesCursor: 0,
    candidatesSortField: "createdAt",
    candidatesSortOrder: -1,
};

export default produce((draft, action) => {
    switch (action.type) {
        case SET_ENABLE_CLOSED_SIDEBAR: {
            draft.enableClosedSidebar = action.enableClosedSidebar;
            return;
        }

        case SET_ENABLE_MOBILE_MENU: {
            draft.enableMobileMenu = action.enableMobileMenu;
            return;
        }

        case RESET_CONVERSATIONS_UI: {
            draft.conversationsView = initialState.conversationsView;
            draft.conversationsCursor = initialState.conversationsCursor;
            draft.conversationsSortField = initialState.conversationsSortField;
            draft.conversationsSortOrder = initialState.conversationsSortOrder;
            return;
        }

        case SET_CONVERSATIONS_VIEW: {
            draft.conversationsView = action.view;
            return;
        }

        case SET_CONVERSATIONS_CURSOR: {
            draft.conversationsCursor = action.cursor;
            return;
        }

        case SET_CONVERSATIONS_SORT: {
            draft.conversationsSortField = action.data.sortField;
            draft.conversationsSortOrder = action.data.sortOrder;
            return;
        }

        case RESET_OFFERS_UI: {
            draft.offersCursor = initialState.offersCursor;
            draft.offersSortField = initialState.offersSortField;
            draft.offersSortOrder = initialState.offersSortOrder;
            return;
        }

        case SET_OFFERS_NAME: {
            draft.offersName = action.name;
            draft.offersCursor = initialState.offersCursor;
            return;
        }

        case SET_OFFERS_CURSOR: {
            draft.offersCursor = action.cursor;
            return;
        }

        case SET_OFFERS_SORT: {
            draft.offersSortField = action.data.sortField;
            draft.offersSortOrder = action.data.sortOrder;
            return;
        }

        case SET_OFFERS_ARCHIVED: {
            draft.offersDisplayArchived = action.offersDisplayArchived;
            return;
        }

        case SET_CANDIDATES_TEXT_SEARCH: {
            draft.candidatesTextSearch = action.text;
            draft.candidatesCursor = initialState.candidatesCursor;
            return;
        }

        case SET_CANDIDATES_CURSOR: {
            draft.candidatesCursor = action.cursor;
            return;
        }

        case SET_CANDIDATES_SORT: {
            draft.candidatesSortField = action.data.sortField;
            draft.candidatesSortOrder = action.data.sortOrder;
            return;
        }

        default: {
            return;
        }
    }
}, initialState);
