import produce from "immer";
import { mongoObjectId } from "../../services/utils";
import {
    ADD_NEW_OFFER_REQUIREMENT,


    ADD_NEW_OFFER_SKILL,






























    ADD_NEW_OFFER_STEP, ADD_NEW_OFFER_STEP_OPTION,











    ADD_NEW_OFFER_STEP_OPTION_DATE, ADD_NEW_OFFER_STEP_OPTION_NUMBER, REMOVE_NEW_OFFER_REQUIREMENT,


    REMOVE_NEW_OFFER_SKILL,






























    REMOVE_NEW_OFFER_STEP, REMOVE_NEW_OFFER_STEP_OPTION,











    REMOVE_NEW_OFFER_STEP_OPTION_DATE, REMOVE_NEW_OFFER_STEP_OPTION_NUMBER, RESET_NEW_OFFER,
    SET_NEW_OFFER,







    SET_NEW_OFFER_ATS, SET_NEW_OFFER_COMPANY,

    SET_NEW_OFFER_CONTRACT_TYPE, SET_NEW_OFFER_DISPLAY_NAME,





    SET_NEW_OFFER_EXTERNAL_REF, SET_NEW_OFFER_ID,




    SET_NEW_OFFER_LOCATION,



    SET_NEW_OFFER_MANDATORY_TREE, SET_NEW_OFFER_NAME,








    SET_NEW_OFFER_REQUIREMENTS,


    SET_NEW_OFFER_SKILLS,






























    SET_NEW_OFFER_SPECIFIC_STEP, SET_NEW_OFFER_STEPS, SET_NEW_OFFER_STEP_CONDITION,


    SET_NEW_OFFER_STEP_END_CHAT, SET_NEW_OFFER_STEP_NAME,

























    SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER, SET_NEW_OFFER_STEP_OPTION_DATE_END,
    SET_NEW_OFFER_STEP_OPTION_DATE_SCORE, SET_NEW_OFFER_STEP_OPTION_DATE_START, SET_NEW_OFFER_STEP_OPTION_DEALBREAKER, SET_NEW_OFFER_STEP_OPTION_LABEL,








    SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER, SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX, SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN,

    SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE, SET_NEW_OFFER_STEP_OPTION_SCORE, SET_NEW_OFFER_STEP_OPTION_VALUE, SET_NEW_OFFER_STEP_REQUIREMENT,


    SET_NEW_OFFER_STEP_SENTENCE, SET_NEW_OFFER_STEP_SKILL,


    SET_NEW_OFFER_STEP_TYPE,


    SET_NEW_OFFER_STEP_VARIABLE,
    SET_NEW_OFFER_STEP_WEIGHT, SET_NEW_OFFER_TEAM
} from "../actions/newOfferActions";


const initialState = {
    newOfferId: null,
    newOfferName: "",
    newOfferDisplayName: "",
    newOfferTeam: null,
    newOfferCompany: null,
    newOfferLocation: "",
    newOfferContractType: "CDI",
    newOfferAts: null,
    newOfferExternalRef: null,
    newOfferRequirements: [],
    newOfferSkills: [],
    newOfferSteps: [],
    newOfferMandatoryTree: null,
    published: false,
};

export default produce((draft, action) => {
    switch (action.type) {
        case RESET_NEW_OFFER:
            return initialState;

        case SET_NEW_OFFER: {
            const { offer } = action;

            draft.newOfferId =
                offer._id !== undefined ? offer._id : initialState.newOfferId;
            draft.newOfferName =
                offer.name !== undefined
                    ? offer.name
                    : initialState.newOfferName;
            draft.newOfferDisplayName =
                offer.displayName !== undefined
                    ? offer.displayName
                    : initialState.newOfferDisplayName;
            draft.newOfferTeam =
                offer.team_id !== undefined
                    ? offer.team_id
                    : initialState.newOfferTeam;
            draft.newOfferCompany =
                offer.company_id !== undefined
                    ? offer.company_id
                    : initialState.newOfferCompany;
            draft.newOfferLocation =
                offer.location !== undefined
                    ? offer.location
                    : initialState.newOfferLocation;
            draft.newOfferContractType =
                offer.contractType !== undefined
                    ? offer.contractType
                    : initialState.newOfferContractType;
            draft.newOfferAts =
                offer.ats_id !== undefined
                    ? offer.ats_id
                    : initialState.newOfferAts;
            draft.newOfferExternalRef =
                offer.externalRef !== undefined
                    ? offer.externalRef
                    : initialState.newOfferExternalRef;
            draft.newOfferRequirements =
                offer.requirements !== undefined
                    ? offer.requirements
                    : initialState.newOfferRequirements;
            draft.newOfferSkills =
                offer.skills !== undefined
                    ? offer.skills
                    : initialState.newOfferSkills;
            draft.newOfferSteps =
                offer.steps !== undefined
                    ? offer.steps
                    : initialState.newOfferSteps;
            draft.newOfferMandatoryTree =
                offer.mandatoryTree_id !== undefined
                    ? offer.mandatoryTree_id
                    : initialState.newOfferMandatoryTree;
            draft.published =
                offer.published !== undefined
                    ? offer.published
                    : initialState.published;

            return;
        }

        case SET_NEW_OFFER_ID:
            draft.newOfferId = action.newOfferId;
            return;

        case SET_NEW_OFFER_NAME:
            draft.newOfferName = action.newOfferName;
            return;

        case SET_NEW_OFFER_DISPLAY_NAME:
            draft.newOfferDisplayName = action.newOfferDisplayName;
            return;

        case SET_NEW_OFFER_TEAM:
            draft.newOfferTeam = action.newOfferTeam;
            return;

        case SET_NEW_OFFER_COMPANY:
            draft.newOfferCompany = action.newOfferCompany;
            return;

        case SET_NEW_OFFER_LOCATION:
            draft.newOfferLocation = action.newOfferLocation;
            return;

        case SET_NEW_OFFER_CONTRACT_TYPE:
            draft.newOfferContractType = action.newOfferContractType;
            return;

        case SET_NEW_OFFER_ATS:
            draft.newOfferAts = action.newOfferAts;
            return;

        case SET_NEW_OFFER_EXTERNAL_REF:
            draft.newOfferExternalRef = action.newOfferExternalRef;
            return;

        case SET_NEW_OFFER_MANDATORY_TREE: {
            // draft.newOfferSteps = removeMandatoryMentions(draft.newOfferSteps, draft.newOfferMandatoryTree);
            draft.newOfferMandatoryTree = action.newOfferMandatoryTree;
            return;
        }

        case SET_NEW_OFFER_REQUIREMENTS:
            draft.newOfferRequirements = action.newOfferRequirements;
            return;

        case ADD_NEW_OFFER_REQUIREMENT: {
            const { defaultStep, _id } = action.newOfferRequirement.requirement;
            const { step } = action.newOfferRequirement;
            draft.newOfferRequirements.push(_id);
            if (step !== undefined) {
                draft.newOfferSteps.push(step);
            } else {
                draft.newOfferSteps.push({
                    ...defaultStep,
                    _id: mongoObjectId(),
                    requirement_id: _id,
                    level: 0,
                });
            }
            return;
        }

        case REMOVE_NEW_OFFER_REQUIREMENT: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e.requirement_id)
                .indexOf(action.requirementId);
            if (stepIndex >= 0) {
                if (draft.newOfferSteps[stepIndex].hasChild) {
                    return;
                }

                if (
                    draft.newOfferSteps[stepIndex].condition &&
                    draft.newOfferSteps[stepIndex].condition.step_id
                ) {
                    const numberOfChildren = draft.newOfferSteps.filter((e) => {
                        if (e.condition && e.condition.step_id) {
                            if (
                                e.condition.step_id ===
                                draft.newOfferSteps[stepIndex].condition.step_id
                            ) {
                                return true;
                            }
                        }
                        return false;
                    });

                    if (numberOfChildren.length <= 1) {
                        const parentStepIndex = draft.newOfferSteps
                            .map((e) => e._id)
                            .indexOf(
                                draft.newOfferSteps[stepIndex].condition.step_id
                            );
                        if (parentStepIndex >= 0) {
                            draft.newOfferSteps[
                                parentStepIndex
                            ].hasChild = false;
                        }
                    }
                }

                draft.newOfferSteps.splice(stepIndex, 1);
            }

            const requirementIndex = draft.newOfferRequirements.indexOf(
                action.requirementId
            );
            if (requirementIndex >= 0) {
                draft.newOfferRequirements.splice(requirementIndex, 1);
            }

            return;
        }

        case SET_NEW_OFFER_SKILLS:
            draft.newOfferSkills = action.newOfferSkills;
            return;

        case ADD_NEW_OFFER_SKILL: {
            const { value, label } = action.newOfferSkill.skill;
            const { step } = action.newOfferSkill;
            draft.newOfferSkills.push(value);
            if (step !== undefined) {
                draft.newOfferSteps.push(step);
            } else {
                draft.newOfferSteps.push({
                    _id: mongoObjectId(),
                    name: label,
                    skill: label,
                    skill_id: value,
                    variable: label,
                    type: "textfield",
                    sentence: `Avez vous de l'expérience avec ${label} ?`,
                    weight: 3,
                    options: [],
                    numberScores: [],
                    dateScores: [],
                    level: 0,
                    endChat: false,
                });
            }
            return;
        }

        case REMOVE_NEW_OFFER_SKILL: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e.skill_id)
                .indexOf(action.skillId);
            if (stepIndex >= 0) {
                if (draft.newOfferSteps[stepIndex].hasChild) {
                    return;
                }

                if (
                    draft.newOfferSteps[stepIndex].condition &&
                    draft.newOfferSteps[stepIndex].condition.step_id
                ) {
                    const numberOfChildren = draft.newOfferSteps.filter((e) => {
                        if (e.condition && e.condition.step_id) {
                            if (
                                e.condition.step_id ===
                                draft.newOfferSteps[stepIndex].condition.step_id
                            ) {
                                return true;
                            }
                        }
                        return false;
                    });

                    if (numberOfChildren.length <= 1) {
                        const parentStepIndex = draft.newOfferSteps
                            .map((e) => e._id)
                            .indexOf(
                                draft.newOfferSteps[stepIndex].condition.step_id
                            );
                        if (parentStepIndex >= 0) {
                            draft.newOfferSteps[
                                parentStepIndex
                            ].hasChild = false;
                        }
                    }
                }

                draft.newOfferSteps.splice(stepIndex, 1);
            }

            const skillIndex = draft.newOfferSkills.indexOf(action.skillId);
            if (skillIndex >= 0) {
                draft.newOfferSkills.splice(skillIndex, 1);
            }

            return;
        }

        case SET_NEW_OFFER_STEP_NAME: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepName.id);
            draft.newOfferSteps[stepIndex].name = action.newOfferStepName.name;
            draft.newOfferSteps[stepIndex].variable =
                action.newOfferStepName.name;
            return;
        }

        case SET_NEW_OFFER_STEP_SKILL: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);

            const prevSkill = draft.newOfferSteps[stepIndex].skill_id;
            const prevRequirement =
                draft.newOfferSteps[stepIndex].requirement_id;

            draft.newOfferSteps[stepIndex].skill_id = action.data.skillId;
            draft.newOfferSteps[stepIndex].requirement_id = null;
            if (draft.newOfferSkills.indexOf(action.data.skillId) < 0) {
                draft.newOfferSkills.push(action.data.skillId);
            }

            if (prevSkill && prevSkill !== null) {
                if (
                    draft.newOfferSteps
                        .map((e) => e.skill_id)
                        .indexOf(prevSkill) < 0
                ) {
                    const prevSkillIndex = draft.newOfferSkills.indexOf(
                        prevSkill
                    );
                    draft.newOfferSkills.splice(prevSkillIndex, 1);
                }
            }

            if (prevRequirement && prevRequirement !== null) {
                if (
                    draft.newOfferSteps
                        .map((e) => e.skill_id)
                        .indexOf(prevRequirement) < 0
                ) {
                    const prevRequirementIndex = draft.newOfferRequirements.indexOf(
                        prevRequirement
                    );
                    draft.newOfferRequirements.splice(prevRequirementIndex, 1);
                }
            }

            return;
        }

        case SET_NEW_OFFER_STEP_REQUIREMENT: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);

            const prevSkill = draft.newOfferSteps[stepIndex].skill_id;
            const prevRequirement =
                draft.newOfferSteps[stepIndex].requirement_id;

            draft.newOfferSteps[stepIndex].requirement_id =
                action.data.requirementId;
            draft.newOfferSteps[stepIndex].skill_id = null;
            if (
                draft.newOfferRequirements.indexOf(action.data.requirementId) <
                0
            ) {
                draft.newOfferRequirements.push(action.data.requirementId);
            }

            if (prevSkill && prevSkill !== null) {
                if (
                    draft.newOfferSteps
                        .map((e) => e.skill_id)
                        .indexOf(prevSkill) < 0
                ) {
                    const prevSkillIndex = draft.newOfferSkills.indexOf(
                        prevSkill
                    );
                    draft.newOfferSkills.splice(prevSkillIndex, 1);
                }
            }

            if (prevRequirement && prevRequirement !== null) {
                if (
                    draft.newOfferSteps
                        .map((e) => e.skill_id)
                        .indexOf(prevRequirement) < 0
                ) {
                    const prevRequirementIndex = draft.newOfferRequirements.indexOf(
                        prevRequirement
                    );
                    draft.newOfferRequirements.splice(prevRequirementIndex, 1);
                }
            }

            return;
        }

        case SET_NEW_OFFER_STEP_CONDITION: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepCondition.id);
            draft.newOfferSteps[stepIndex].condition.values =
                action.newOfferStepCondition.values;
            return;
        }

        case SET_NEW_OFFER_STEP_TYPE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepType.id);
            draft.newOfferSteps[stepIndex].type = action.newOfferStepType.type;
            draft.newOfferSteps[stepIndex].endChat = false;
            return;
        }

        case SET_NEW_OFFER_STEP_SENTENCE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepSentence.id);
            draft.newOfferSteps[stepIndex].sentence =
                action.newOfferStepSentence.sentence;
            return;
        }

        case SET_NEW_OFFER_STEP_END_CHAT: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepEndChat.id);
            draft.newOfferSteps[stepIndex].endChat =
                action.newOfferStepEndChat.endChat;
            return;
        }

        case SET_NEW_OFFER_STEP_VARIABLE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepVariable.id);
            draft.newOfferSteps[stepIndex].variable =
                action.newOfferStepVariable.variable;
            return;
        }

        case SET_NEW_OFFER_STEP_WEIGHT: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepWeight.id);
            draft.newOfferSteps[stepIndex].weight =
                action.newOfferStepWeight.weight;
            return;
        }

        case ADD_NEW_OFFER_STEP_OPTION: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.stepId);
            draft.newOfferSteps[stepIndex].options.push({
                _id: mongoObjectId(),
                label: "",
                value: "",
                score: 0,
                dealbreaker: false,
            });
            return;
        }

        case REMOVE_NEW_OFFER_STEP_OPTION: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.stepOptionIndex.id);
            draft.newOfferSteps[stepIndex].options.splice(
                action.stepOptionIndex.optionIndex,
                1
            );
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_LABEL: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionLabel.id);
            draft.newOfferSteps[stepIndex].options[
                action.newOfferStepOptionLabel.optionIndex
            ].label = action.newOfferStepOptionLabel.label;
            draft.newOfferSteps[stepIndex].options[
                action.newOfferStepOptionLabel.optionIndex
            ].value = action.newOfferStepOptionLabel.label;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_VALUE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionValue.id);
            draft.newOfferSteps[stepIndex].options[
                action.newOfferStepOptionValue.optionIndex
            ].value = action.newOfferStepOptionValue.value;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_SCORE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionScore.id);
            draft.newOfferSteps[stepIndex].options[
                action.newOfferStepOptionScore.optionIndex
            ].score = action.newOfferStepOptionScore.score;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_DEALBREAKER: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionDealbreaker.id);
            draft.newOfferSteps[stepIndex].options[
                action.newOfferStepOptionDealbreaker.optionIndex
            ].dealbreaker = action.newOfferStepOptionDealbreaker.dealbreaker;
            return;
        }

        case ADD_NEW_OFFER_STEP_OPTION_NUMBER: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.stepId);
            draft.newOfferSteps[stepIndex].numberScores.push({
                minValue: 0,
                maxValue: 0,
                score: 0,
                dealbreaker: false,
            });
            return;
        }

        case REMOVE_NEW_OFFER_STEP_OPTION_NUMBER: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.stepOptionNumberIndex.id);
            draft.newOfferSteps[stepIndex].numberScores.splice(
                action.stepOptionNumberIndex.scoreIndex,
                1
            );
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionNumberMin.id);
            draft.newOfferSteps[stepIndex].numberScores[
                action.newOfferStepOptionNumberMin.scoreIndex
            ].minValue = action.newOfferStepOptionNumberMin.min;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionNumberMax.id);
            draft.newOfferSteps[stepIndex].numberScores[
                action.newOfferStepOptionNumberMax.scoreIndex
            ].maxValue = action.newOfferStepOptionNumberMax.max;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionNumberScore.id);
            draft.newOfferSteps[stepIndex].numberScores[
                action.newOfferStepOptionNumberScore.scoreIndex
            ].score = action.newOfferStepOptionNumberScore.score;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.newOfferStepOptionNumberDealbreaker.id);
            draft.newOfferSteps[stepIndex].numberScores[
                action.newOfferStepOptionNumberDealbreaker.scoreIndex
            ].dealbreaker =
                action.newOfferStepOptionNumberDealbreaker.dealbreaker;
            return;
        }

        case ADD_NEW_OFFER_STEP_OPTION_DATE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.stepId);
            if (draft.newOfferSteps[stepIndex].dateScores === undefined) {
                draft.newOfferSteps[stepIndex].dateScores = [];
            }
            draft.newOfferSteps[stepIndex].dateScores.push({
                minValue: new Date(),
                maxValue: new Date(),
                score: 0,
                dealbreaker: false,
            });
            return;
        }

        case REMOVE_NEW_OFFER_STEP_OPTION_DATE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);
            draft.newOfferSteps[stepIndex].dateScores.splice(
                action.data.scoreIndex,
                1
            );
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_DATE_START: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);
            draft.newOfferSteps[stepIndex].dateScores[
                action.data.scoreIndex
            ].startValue = action.data.start;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_DATE_END: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);
            draft.newOfferSteps[stepIndex].dateScores[
                action.data.scoreIndex
            ].endValue = action.data.end;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_DATE_SCORE: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);
            draft.newOfferSteps[stepIndex].dateScores[
                action.data.scoreIndex
            ].score = action.data.score;
            return;
        }

        case SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER: {
            const stepIndex = draft.newOfferSteps
                .map((e) => e._id)
                .indexOf(action.data.id);
            draft.newOfferSteps[stepIndex].dateScores[
                action.data.scoreIndex
            ].dealbreaker = action.data.dealbreaker;
            return;
        }

        case SET_NEW_OFFER_STEPS: {
            draft.newOfferSteps = action.newOfferSteps;
            return;
        }

        case SET_NEW_OFFER_SPECIFIC_STEP: {
            draft.newOfferSteps[action.newOfferStep.index] =
                action.newOfferStep;
            return;
        }

        case ADD_NEW_OFFER_STEP: {
            draft.newOfferSteps.push(action.newOfferStep);
            if (action.newOfferStep.skill_id !== undefined) {
                draft.newOfferSkills.push(action.newOfferStep.skill_id);
            }
            if (action.newOfferStep.requirement_id !== undefined) {
                draft.newOfferRequirements.push(
                    action.newOfferStep.requirement_id
                );
            }
            return;
        }

        case REMOVE_NEW_OFFER_STEP: {
            const { id, requirementId, skillId } = action.indexStep;
            const stepIndex = draft.newOfferSteps.map((e) => e._id).indexOf(id);

            if (
                draft.newOfferSteps[stepIndex].condition &&
                draft.newOfferSteps[stepIndex].condition.step_id
            ) {
                const numberOfChildren = draft.newOfferSteps.filter((e) => {
                    if (e.condition && e.condition.step_id) {
                        if (
                            e.condition.step_id ===
                            draft.newOfferSteps[stepIndex].condition.step_id
                        ) {
                            return true;
                        }
                    }
                    return false;
                });

                if (numberOfChildren.length <= 1) {
                    const parentStepIndex = draft.newOfferSteps
                        .map((e) => e._id)
                        .indexOf(
                            draft.newOfferSteps[stepIndex].condition.step_id
                        );
                    if (parentStepIndex >= 0) {
                        draft.newOfferSteps[parentStepIndex].hasChild = false;
                    }
                }
            }

            draft.newOfferSteps.splice(stepIndex, 1);
            
            if (skillId !== null) {
                const skillIndex = draft.newOfferSkills.indexOf(skillId);
                if (skillIndex >= 0) draft.newOfferSkills.splice(skillIndex, 1);
            }
            if (requirementId !== null) {
                const requirementIndex = draft.newOfferRequirements.indexOf(
                    requirementId
                );
                if (requirementIndex >= 0)
                    draft.newOfferRequirements.splice(requirementIndex, 1);
            }

            return;
        }

        default: {
            return;
        }
    }
}, initialState);
