import React from "react";
import { withRouter } from "react-router-dom";
import {
    Button,
    FormGroup,





    Label, Modal,

    ModalBody,
    ModalFooter, ModalHeader
} from "reactstrap";
import AuthService from "../../services/AuthService";



const Auth = new AuthService();

class DisconnectedComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalId: null,
            modal: false,
        };
    }

    componentDidMount() {
        const intervalId = setInterval(() => {
            const logged = Auth.loggedIn();

            if (!logged) {
                this.setState({ modal: true });
            }
        }, 60000);

        this.setState({ intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    goToLogin = () => {
        this.setState({ modal: false });

        setTimeout(() => {
            this.props.history.push("/login");
        }, 500);
    };

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>Session inactive</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>
                            Vous avez été déconnecté car votre session est resté
                            inactive trop longtemps, cliquez sur le bouton ci
                            dessous pour vous reconnecter.
                        </Label>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.goToLogin}>
                        Se reconnecter
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withRouter(DisconnectedComponent);
