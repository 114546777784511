import React, { Fragment } from "react";
import logo from "./../../assets/hollo/logo.png";



class HeaderLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            mobile: false,
            activeSecondaryMenuMobile: false,
        };
    }

    state = {
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 280,
        noTouchOpen: false,
        noTouchClose: false,
    };

    render() {
        return (
            <Fragment>
                <a href="/app">
                    <div className="app-header__logo">
                        <img className="logo-src" src={logo} alt="" />
                        <div className="header__pane ml-auto"></div>
                    </div>
                </a>
            </Fragment>
        );
    }
}

export default HeaderLogo;
